import { Flex } from 'cdk'
import { type ColorName, cssvarColor } from 'design-tokens'
import { type ReactNode } from 'react'
import styled from 'styled-components'
import { shouldForwardProp } from 'ui'

const ArrowWrapper = styled(Flex).withConfig({ shouldForwardProp }).attrs({
  $align: 'center',
  $justify: 'center',
})`
  width: 40px;
  height: 40px;
`

type Attrs = {
  disabled: boolean
  background: ColorName
  backgroundActive: ColorName
  backgroundHover: ColorName
  iconColorName: ColorName
  iconColorNameActive: ColorName
  iconColorNameHover: ColorName
}

const Press = styled(Flex).withConfig({ shouldForwardProp })<Attrs>`
  padding: 0;
  border: none;
  outline: 0;
  font-family: inherit;
  cursor: pointer;
  appearance: none;

  .arrow {
    /* stylelint-disable declaration-property-value-keyword-no-deprecated */
    background-color: ${({ background }) => cssvarColor(background)};

    svg {
      color: ${({ iconColorName }) => cssvarColor(iconColorName)};
    }
  }

  &:hover {
    &:not([disabled]) {
      .arrow {
        background-color: ${({ backgroundHover }) => cssvarColor(backgroundHover)};

        svg {
          color: ${({ iconColorNameHover }) => cssvarColor(iconColorNameHover)};
        }
      }
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  &:active {
    &:not([disabled]) {
      .arrow {
        background-color: ${({ backgroundActive }) => cssvarColor(backgroundActive)};

        svg {
          color: ${({ iconColorNameActive }) => cssvarColor(iconColorNameActive)};
        }
      }
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  &:disabled,
  &[disabled] {
    opacity: 0.4;
    cursor: auto;
  }
`

type Props = {
  children: ReactNode
  disabled?: boolean
  onClick: () => void
  tabIndex?: number
  background?: ColorName
  backgroundActive?: ColorName
  backgroundHover?: ColorName
  iconColorName?: ColorName
  iconColorNameActive?: ColorName
  iconColorNameHover?: ColorName
}

export const TertiaryButtonIconOnly = ({
  background = 'transparent',
  backgroundActive = 'primary-80',
  backgroundHover = 'primary',
  children,
  disabled = false,
  iconColorName = 'primary',
  iconColorNameActive = 'white',
  iconColorNameHover = 'white',
  onClick,
  tabIndex,
}: Props) => (
  <Press
    $borderRadius={40}
    background={background}
    backgroundActive={backgroundActive}
    backgroundHover={disabled ? background : backgroundHover}
    data-test-id="tertiary-button-icon-only"
    disabled={disabled}
    iconColorName={iconColorName}
    iconColorNameActive={iconColorNameActive}
    iconColorNameHover={disabled ? iconColorName : iconColorNameHover}
    onClick={onClick}
    tabIndex={tabIndex}
  >
    <ArrowWrapper $borderColorName="primary-30" $borderRadius={40} $borderSize={1} className="arrow">
      {children}
    </ArrowWrapper>
  </Press>
)
