import { PositionRelative, Pressable } from 'cdk'
import { BORDER_RADIUS_CIRCLE, COLOR_ERROR } from 'design-tokens'
import { Icon } from 'icons'
import styled from 'styled-components'
import { useTrackEventClick } from '~/domains/analytics/hooks/useTrackEventClick'
import { useRootHistory } from '~/hooks/useRootHistory'
import { getLocalStorage } from '~/utils/localStorage/getLocalStorage'
import { setLocalStorage } from '~/utils/localStorage/setLocalStorage'

const MgmBadge = styled.div`
  position: absolute;
  right: -2px;
  bottom: -2px;
  width: 12px;
  height: 12px;
  border-radius: ${BORDER_RADIUS_CIRCLE};
  background-color: ${COLOR_ERROR};
`

export const LinkMemberGetMember = () => {
  const isMgmPageShown = getLocalStorage('settings-invite-card-shown')
  const rootHistory = useRootHistory()
  const trackEventClick = useTrackEventClick()

  const handleClick = () => {
    if (isMgmPageShown == null) {
      setLocalStorage('settings-invite-card-shown', new Date().toISOString())
    }

    trackEventClick('settings-invite.from-mgm-icon')
    rootHistory.push('/settings/invite')
  }

  return (
    <Pressable onClick={handleClick}>
      <PositionRelative>
        <Icon colorName="neutral-110" fillColorName="lighter" name="srns-member-get-member" size={24} />
        {!isMgmPageShown && <MgmBadge />}
      </PositionRelative>
    </Pressable>
  )
}
