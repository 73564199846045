import { type ReactNode } from 'react'
import { useAuthState } from '~/domains/auth/components/AuthStateProvider'
import { useUserRedirectEffect } from '~/hooks/useUserRedirectEffect'
import { type Route } from '~/utils/getRoute'

type Props = {
  children: ReactNode
  target?: Route
  withNextJsRouting?: boolean
}

export const AssertAnonymous = ({ children, target, withNextJsRouting = false }: Props) => {
  const { isAuthenticated } = useAuthState()

  useUserRedirectEffect(isAuthenticated, target, withNextJsRouting)

  if (isAuthenticated) {
    return null
  }

  return <>{children}</>
}
